import React from 'react';
import PropTypes from 'prop-types';
import { SocialProfileWrapper, SocialProfileItem } from './socialProfile.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from 'common/src/components/Text';
import { OutboundLink } from "gatsby-plugin-google-analytics"

const SocialProfile = ({ items, className, textStyle  }) => {
  const addAllClasses = ['social_profiles'];

  if (className) {
    addAllClasses.push(className);
  }

  if (items === undefined) {
    items = [];
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(' ')}>
      {items.map((item, index) => (        
         <SocialProfileItem key={`social-item-${index}`} className="social_profile_item" >
          <OutboundLink href={item.url || '#'} alt={item.alt} aria-label="social icon" target="_blank" rel="noopener noreferrer">
            {/* <i className={item.icon}></i> */}
            {(item.iconName == 'wantedly' || item.iconName == 'qiita') ? 
              (<Text content={item.iconName.toUpperCase()[0]} {...textStyle} />) : 
              (<FontAwesomeIcon icon={[item.iconGroup, item.iconName]} />)}
            
          </OutboundLink>
        </SocialProfileItem>
      ))}
    </SocialProfileWrapper>
  );
};

SocialProfile.propTypes = {
  items: PropTypes.array,
  className: PropTypes.object
}

export default SocialProfile;

SocialProfile.defaultProps = {
  textStyle: {
    fontSize: '17px',
    fontWeight: '800',
    pt: '14px'
  },
}